@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Scheherazade+New:wght@400;700&display=swap');
:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
    --primary: #f65d4e;
    --primary_hover: #f4402f;
    --text: #444;
    --accent: #000;
    --lighter: #999;
    --border: #e6e6e6;
    --ashes: #f7f7f7;

  
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'kalpurush','hind siliguri';
  /* font-size: 18px; */

}

html, body { 
  scroll-behavior: smooth !important;
}
html:focus-within {
  scroll-behavior: smooth;

}


@font-face {
  font-family: kalpurush;
  src: url(fonts/kalpurush.ttf);
}
@font-face {
  font-family: kalpurushansi;
  src: url(fonts/kalpurush\ ANSI.ttf);
}
@font-face {
  font-family: sutonnymj;
  src: url(fonts/SUTONNYMJ.TTF);
}
@font-face {
  font-family: arabickufi;
  src: url(fonts/DroidKufi-Regular.ttf
  );
}
/* @font-face {
  font-family: arabicquranic;
  src: url(fonts/AmiriQuran.woff2
  );
} */
@font-face {
  font-family: 'amiri_quran';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Amiri Quran'), url("fonts/AmiriQuran.woff2") format('woff2')
}

@font-face {
  font-family: 'LPMQIsepMisbah';
  src: local('LPMQ Isep Misbah'), url("fonts/lpmq.woff2") format('woff2');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: uthmani;
  src: url(fonts/UthmanTN1Ver10.otf
  );
}
@font-face {
  font-family: amiri;
  src: url(fonts/amiri-regular.ttf
  );}
  @font-face {
    font-family: books;
    src: url(fonts/ilm-book.woff2
    );
}

body{
  background-color: #fbfeff;
  /* font-family: 'kalpurush'; */
  /* font-family: 'books'; */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 

.lalu{

  transition: all 0.3s ease-in;

}

.heart {
	font-size: 150px;
	color: #e00;
  background:red;
  height: 34px;
  width: 34px;
	animation: beat .25s infinite alternate;
	transform-origin: center;
}

/* Heart beat animation */
@keyframes beat{
   from {background-color: red;}
  to {background-color: yellow;}
}

@keyframes animate {
  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }
  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }
}

/* .splash {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: #59d9ff;
  overflow: hidden;
} */
.splash li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 8s linear infinite;
}




.splash li:nth-child(0) {
  left: 83%;
  width: 138px;
  height: 138px;
  bottom: -138px;
  animation-delay: 0s;
}
.splash li:nth-child(1) {
  left: 80%;
  width: 190px;
  height: 190px;
  bottom: -190px;
  animation-delay: 0s;
}
.splash li:nth-child(2) {
  left: 46%;
  width: 211px;
  height: 211px;
  bottom: -211px;
  animation-delay: 0s;
}
.splash li:nth-child(3) {
  left: 89%;
  width: 169px;
  height: 169px;
  bottom: -169px;
  animation-delay: 0s;
}
.splash li:nth-child(4) {
  left: 36%;
  width: 188px;
  height: 188px;
  bottom: -188px;
  animation-delay:0s;
}
.splash li:nth-child(5) {
  left: 60%;
  width: 206px;
  height: 206px;
  bottom: -206px;
  animation-delay: 1s;
}
.splash li:nth-child(6) {
  left: 49%;
  width: 214px;
  height: 214px;
  bottom: -214px;
  animation-delay: 1s;
}
.splash li:nth-child(7) {
  left: 27%;
  width: 177px;
  height: 177px;
  bottom: -177px;
  animation-delay: 1s;
}
.splash li:nth-child(8) {
  left: 2%;
  width: 213px;
  height: 213px;
  bottom: -213px;
  animation-delay: 1s;
}
.splash li:nth-child(9) {
  left: 52%;
  width: 211px;
  height: 211px;
  bottom: -211px;
  animation-delay: 39s;
}




.tab-list {
  height: 50px;
  display: flex;
  list-style: none;
  font-size: 18px;
  padding: 0;
  margin: 0;
}

.tabs {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #80808013;
  position: relative;
  cursor: pointer;
}

.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.active-tabs {
  background: #63ffe5;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 4px;
  background: #5893f1;
}
/* Pagination Design */
.pagination{
  display: flex;
  justify-content: center;
}
.page_item{
  padding: 0vmax 1.5vmax;
  border: 1px solid rgba(0, 0, 0,0.275);
  transition: all 0.3s;
}
.page_item:first-child{
  border-radius: 5px 0 0 5px;
}
.page_item:last-child{
  border-radius: 0 5px 5px 0;
}
.page_item:hover{
  background: #00000040;
}
.pageItemActive{
  background: #141922;
}
.pageLinkActive{
  color: white;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: #f1f1f1 solid 1px;
}
#customers th{
  background-color: #00bbe5;

}
#customers td, #customers th {
  /* border: 1px solid #ddd; */
  padding: 12px;
}

/* #customers tr:nth-child(even){background-color: #f2f2f2;} */

/* #customers tr:hover {background-color: #ddd;} */

#namess th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  /* border: 1px solid #ddd; */
  color: white;
}
#namess {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#namess td, #namess th {
  /* border: 1px solid #ddd; */
  padding: 8px;
}

/* #namess tr:nth-child(even){background-color: #f2f2f2;} */

/* #namess tr:hover {background-color: #ddd;} */

#namess th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
}

.hero{
  background: linear-gradient(44deg, #ff0000, #a70404, #ff3019);
  background-size: 600% 600%;

  -webkit-animation: AnimationName 15s ease infinite;
  -moz-animation: AnimationName 15s ease infinite;
  animation: AnimationName 15s ease infinite;
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 46%}
  50%{background-position:100% 55%}
  100%{background-position:0% 46%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 46%}
  50%{background-position:100% 55%}
  100%{background-position:0% 46%}
}
@keyframes AnimationName {
  0%{background-position:0% 46%}
  50%{background-position:100% 55%}
  100%{background-position:0% 46%}
}


/* ------------ */
