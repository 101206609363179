@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion section */


/* Style the buttons that are used to open and close the accordion panel */
/* .accordion {

  color: #444;
    cursor: pointer;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
    background: #6de4ff;
    box-shadow: 0px 2px 6px 0px lightgrey;
} */

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */



/* Style the accordion content title */


/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
